import { Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import iconExams from '../../../../../assets/images/no-message.png';

interface Props {
  open: boolean;
  handleClose: () => void;
  message?: string;
}

const InfoRequest = ({ handleClose, open, message }: Props) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Grid container spacing={2}>
          {/* <Grid item xs={12}>
            <Typography variant="h2" color="primary" textAlign="center">
              {pendingEvaluations > 0 && pendingApproves > 0
                ? 'Evaluaciones y aprobaciones de resultados pendientes'
                : pendingEvaluations > 0
                ? 'Evaluaciones pendientes'
                : 'Aprobación de resultados pendientes'}
            </Typography>
          </Grid> */}
          <Grid item container xs={12} justifyContent="center">
            <img
              style={{
                width: 'auto',
                height: '200px',
              }}
              src={iconExams}
              alt="exams student"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="1rem" textAlign="center">
              {message}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button onClick={handleClose} variant="contained" color="primary">
              Continuar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoRequest;
